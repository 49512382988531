import { takeEvery, call, select, all, delay, debounce, take, fork, cancel, put } from 'redux-saga/effects';
import * as actions from './actions.js';
import * as selectors from './selectors.js';
import * as appSelectors from '../app/selectors.js';
import * as app from '../app';
import dreamdb from '@dw/dreamdb-client';
import { back } from '@dreamworld/router';
import { get, isEmpty, map, isEqual } from 'lodash-es';
import { store } from '../store.js';
import { getSnackbar } from '../utils.js';

const CONNECTION_STATUS_CHANGED = 'DREAMDB_CONNECTION_STATUS_CHANGED';
const DREAMDB_DOCS_UPDATED = 'DREAMDB_DOCS_UPDATED';
const DREAMDB_QUERY_SNAPSHOT = 'DREAMDB_QUERY_SNAPSHOT';
let db; // Database instance of the DreamDB.

/**
 * Checks whether playe key is loaded on video-player-server or not.
 */
function* isPlayKeyLoaded(videoId) {
  const state = yield select(appSelectors.playServerUrl);
  const playerServerUrl = appSelectors.config(state).playerServerUrl;
  try {
    //send a request on http://localhost/play/:videoId and if 404 response is received then play key is not loaded.
    const response = yield call(window.fetch, `${playerServerUrl}/play/${videoId}`);
    const loaded = response.ok;
    // console.debug(`isPlayKeyLoaded=${loaded}, videoId=${videoId}, status=${response.status}`);
    return loaded;
  } catch (error) {
    console.error('isPlayKeyLoaded. failed.', error);
    return false;
  }
}

/**
 * Auto plays when any play is eligible to be played at present.
 */
function* autoPlay() {
  const state = yield select();
  const currPlay = selectors.currPlay(state);
  if (isEmpty(currPlay)) return;

  const playKeyLoaded = yield call(isPlayKeyLoaded, currPlay.videoId);
  if (!playKeyLoaded) return;

  console.info('autoPlay: going to start play as playKey is already loaded.', currPlay._id);
  // yield call(onStartPlay, { playStatusId: currPlay._id });
  yield put(actions.startPlay(currPlay._id));
}

function* playFlow() {
  while (true) {
    const { videoId, playStatusId } = yield take([actions.PLAY_STARTED]);
    const task = yield fork(function* () {
      while (true) {
        yield delay(10000);
        yield call(updatePlayedDurationOnRemote, videoId, playStatusId);
      }
    });
    yield take([actions.PLAY_COMPLETED, actions.PLAY_PAUSED]);
    yield cancel(task);
  }
}

/**
 * Updates played duration on dreamdb every 10 seconds.
 * @param {String} videoId Video Id
 * @param {String} playStatusId Play status Id
 */
function* updatePlayedDurationOnRemote(videoId, playStatusId) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  if (videoDetail._deleted && playStatus.status != 'PLAYED') {
    return;
  }

  const doc = {
    ...playStatus,
    status: 'PLAYING',
  };
  console.info('updatePlayedDurationOnRemote', doc._id, doc.playedDuration);
  yield call(saveDoc, doc);
}

/**
 * Start preview session
 * @param {*} param0
 *  @property {String} playStatusId Play status Id.
 */
function* onOpenPreview({ playStatusId }) {
  try {
    console.debug('onOpenPreview : requested', playStatusId);
    const state = yield select();
    const playStatusDoc = selectors.doc(state, playStatusId);
    const videoId = playStatusDoc?.videoId;
    const videoDoc = selectors.doc(state, videoId);
    if (videoDoc._deleted) {
      return;
    }
    if (playStatusDoc.previewKey) {
      yield call(() => window.VideoManager.previewOpen(videoId, get(playStatusDoc, 'previewKey')));
      console.debug('previewOpen: done', videoId);
    } else {
      console.warn('onOpenPreview : no preview key');
    }
  } catch (error) {
    console.error('onOpenPreview error', error);
    const snackbar = yield getSnackbar();
    snackbar.show({ message: `Failed to load preview key: ${error}`, type: 'ERROR' });
  }
}

/**
 * Stop preview session
 * @param {*} param0
 *  @property {String} videoId Video Id.
 */
function* onClosePreview({ videoId }) {
  try {
    console.info('onClosePreview requested', videoId);
    const state = yield select();
    const videoDoc = selectors.doc(state, videoId);
    if (videoDoc._deleted) {
      return;
    }
    console.info('onClosePreview', videoId);
    yield window.VideoManager.previewClose(videoId);
  } catch (error) {
    console.error('onClosePreview error', error);
  }
}

/**
 * Stop play session
 * @param {*} param0
 *  @property {String} videoId Video Id.
 */
function* onClosePlay({ videoId }) {
  try {
    const state = yield select();
    const videoDoc = selectors.doc(state, videoId);
    if (videoDoc._deleted) {
      return;
    }
    console.info('onOpenPlay', videoId);
    yield window.VideoManager.playClose(videoId);
  } catch (error) {
    console.error('onClosePlay error', error);
  }
}

function* onStartPreview({ playStatusId }) {
  try {
    console.info('onStartPreview', playStatusId);
    yield call(onOpenPreview, { playStatusId });
    const state = yield select();
    const playStatusdoc = selectors.doc(state, playStatusId);
    const videoId = playStatusdoc?.videoId;
    const videoDoc = selectors.doc(state, videoId);

    if (isEmpty(videoDoc)) {
      throw 'No video document found.';
    }

    const { _id, name, previewUrl } = videoDoc;
    const previewId = 'preview-' + _id;
    const { playerServerUrl } = appSelectors.config(state);
    const url = `${playerServerUrl}/videos/${_id}/${previewUrl}`;

    //HACK: As of now, exact duration of the preview Track is not known (and not saved on the Video doc).
    //So, we have hard-coded it to 30 seconds for now. In future, this will be read from the video doc.
    const tracks = [{ id: previewId, title: name, duration: 30, url }];
    const startTrack = 0;
    const startTime = 0;

    controllerWindowShown = false;

    
    yield call(() => window.VideoPlayer.start({videoId: previewId, tracks, startTrack, startTime, onStatus: onVideoPlayerStatus}));
  } catch (error) {
    console.error('onStartPreview error', error);
  }
}

function* onStopPreview({ videoId }) {
  try {
    console.info('onStopPreview', videoId);
    yield call(() => window.VideoPlayer.stop('preview-' + videoId));
    yield call(hideControllerWindow, 'preview-' + videoId);
  } catch (error) {
    console.error('onStopPreview error', error);
  }
}

/**
 * Before player is launched this is set to `false`; and on first video status with (PLAYING) value
 * this is set to `true` and controller window is shown.
 */
let controllerWindowShown = false;
const onVideoPlayerStatus = ({ videoId, status, tracks, error }) => {
  // Possible `status` are: PLAYING, PAUSED, STOPPED, COMPLETED, ERROR;
  if (status === 'PLAYING' && !controllerWindowShown) {
    controllerWindowShown = true;
    // console.debug('onVideoPlayerStatus: PLAYING', videoId);
    showControllerWindow(videoId);
  }

  if (status === 'COMPLETED' || status === 'ERROR') {
    hideControllerWindow(videoId);
  }

  //No need to update played duration for preview videos
  if (videoId.startsWith('preview-')) {
    return;
  }

  if (status === 'PLAYING') {
    const playedDuration = tracks.map(track => track.played);
    store.dispatch(
      actions.updatePlayedDuration({
        playedDuration,
      })
    );
    return;
  }

  const playStatusId = selectors.currPlay(store.getState())?._id;

  if (status === 'PAUSED') {
    store.dispatch(actions.playPaused({ videoId, playStatusId }));
  }

  if (status === 'COMPLETED') {
    store.dispatch(actions.playCompleted({ videoId, playStatusId }));
  }
};

function showControllerWindow(videoId) {
  // 2500 ms delay is required to show controller window on top of vlc player.
  window.setTimeout(() => {
    //window.VideoController.hide(videoId);
    window.VideoController.show(videoId);
    console.debug('showControllerWindow: done');
  }, 5000);
}

function hideControllerWindow(videoId) {
  window.VideoController.hide(videoId);
  console.debug('hideControllerWindow: done');
  localStorage.removeItem(`videoSubtitles.${videoId}`);
  localStorage.removeItem('curPlayKendraId');
}

/**
 * Creates Tracks for a VideoPlayStatus.
 * If prefixVideoId is provided, then it's added as prefix to the videoId.
 * If postfixVideoId is provided, then it's added as postfix to the videoId.
 * 
 */
function createTracks(vpsId, state) {
  const vps = selectors.doc(state, vpsId);
  const tracks = [];

  //prefix track
  vps.prefixVideoId && tracks.push(createTrack(vps.prefixVideoId, state));

  //main track
  tracks.push(createTrack(vps.videoId, state));
  
  //postfix track
  vps.postfixVideoId && tracks.push(createTrack(vps.postfixVideoId, state));

  return tracks;
}

function createTrack(videoId, state) {
  const videoDoc = selectors.doc(state, videoId);
  if (isEmpty(videoDoc)) {
    throw 'No video document found.';
  }

  const { _id, name, duration, playUrl } = videoDoc;
  const { playerServerUrl } = appSelectors.config(state);
  const url = `${playerServerUrl}/videos/${_id}/${playUrl}`;
  return { id: _id, title: name, duration, url };
}

function* onStartPlay({ playStatusId }) {
  try {
    console.info('onStartPlay', playStatusId);
    const state = yield select();
    const vps = selectors.doc(state, playStatusId);
    const videoId = vps.videoId;

    //Store video subtitles & curPlayKendraId on local-storage so controller window can read them
    const video = selectors.videoDetail({ state, videoId });
    if (video.subtitles) {
      localStorage.setItem(`videoSubtitles.${video._id}`, JSON.stringify(video.subtitles));
    }
    localStorage.setItem('curPlayKendraId', vps.kendraId);


    const { resumePlaybackTime } = appSelectors.config(state);
    const tracks = createTracks(playStatusId, state);
    const {startTrack, startTime} = findStartTrackAndTime(vps.playedDuration, resumePlaybackTime);

    controllerWindowShown = false;
    yield call(() => window.VideoPlayer.start({videoId, tracks, startTrack, startTime, onStatus: onVideoPlayerStatus}));
    yield put(actions.playStarted({ videoId, playStatusId }));
  } catch (error) {
    console.error('onStartPlay error', error);
  }
}

/**
 * Last array in element having non-zero value is used as `startTime` and it's index as `startTrack`.
 * 
 * @param {Array[Number]} playedDuration  
 * @param {Number} resumePlaybackTime - Time in seconds to resume playback by few seconds earlier.
 * @returns {Object} e.g. `{startTrack: 1, startTime: 50}`
 */
function findStartTrackAndTime(playedDuration, resumePlaybackTime) {
  playedDuration = playedDuration || [];
  resumePlaybackTime = resumePlaybackTime || 0;

  let startTrack = 0;
  let startTime = 0;
  for (let i = playedDuration.length-1; i >= 0; i--) {
    if (playedDuration[i] > 0) {
      startTrack = i;
      startTime = playedDuration[i];
      break;
    }
  }

  startTime = Math.max(0, startTime - resumePlaybackTime);
  return { startTrack, startTime };
  
}

function* onStopPlay({ videoId }) {
  try {
    console.info('onStopPlay', videoId);
    yield call(() => window.VideoPlayer.stop(videoId));
    yield call(hideControllerWindow, videoId);
  } catch (error) {
    console.error('onStopPlay error', error);
  }
}

/**
 * Sets startAt time & status to `PLAYING`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id.
 */
function* onPlayStarted({ videoId, playStatusId }) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  if (videoDetail._deleted) {
    return;
  }
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  const doc = {
    ...playStatus,
    status: 'PLAYING',
    startedAt: Date.now(),
  };
  console.info('onPlayStarted', doc._id);
  
  yield call(saveDoc, doc);
}

/**
 * Sets status to `PAUSED`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id.
 */
function* onPlayPaused({ videoId, playStatusId }) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  console.log('onPlayPaused called', { videoDetail, videoId, playStatusId });
  if (videoDetail._deleted) {
    return;
  }
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  const doc = {
    ...playStatus,
    status: 'PAUSED',
  };
  console.info('onPlayPaused', doc._id);
  try {
    yield call(saveDoc, doc);
  } catch (e) {
    //this fails often due to update conflict
    console.error('onPlayPaused: failed', e);
  }
}

/**
 * Sets completedAt time & status to `COMPLETED`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id.
 */
function* onPlayCompleted({ videoId, playStatusId }) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  if (videoDetail._deleted) {
    return;
  }
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  const doc = {
    ...playStatus,
    status: 'PLAYED',
    completedAt: Date.now(),
  };
  console.info('onPlayCompleted', doc._id);
  yield call(saveDoc, doc);
  yield call(deletePlayedVideos);
  yield delay(2000);
  console.log('going to do poweroff');
  yield call(() => window.Rpi.powerOff());
  // yield window.RpiManager?.fsyncChromeCache && window.RpiManager.fsyncChromeCache();
}

/**
 * Invoked when media play/pause button (on Player chasis) is pressed.
 *
 * It works as follows:
 * 1. If no play is available to play, then it does nothing.
 * 2. Next Play's time is not yet arrived, then starts the preview.
 * 3. Next Play's time is arrived and unlocked, then starts the play;
 *    otherwise, opens the unlock dialog; and upon unlock play is started.
 */
function* onMediaPlayPause() {
  const state = yield select();

  const currentAvailableVps = selectors.currentAvailablePlay(state);
  if (currentAvailableVps) {
    const playKeyLoaded = yield call(isPlayKeyLoaded, currentAvailableVps.videoId);
    if (playKeyLoaded) {
      console.info('onMediaPlayPause: going to start play as already unlocked..', currentAvailableVps._id);
      yield put(actions.startPlay(currentAvailableVps._id));
      return;
    } else {
      console.info('onMediaPlayPause: going to start unlock flow.', currentAvailableVps._id);
      yield put(actions.unlock(currentAvailableVps._id));
      return;
    }
  }

  const nextVps = selectors.nextAvailablePlay(state);
  if (!nextVps) {
    console.info('onMediaPlayPause: no pending plays found.');
    return;
  }
  console.info('onMediaPlayPause: going to start preview.', nextVps._id);
  yield put(actions.startPreview(nextVps._id));
}

/**
 * When current page is PLAYER & that play or video is deleted, stop play & navigates user to home page.
 */
function* stopPlayWhenPlayOrVideoDeleted() {
  const state = yield select();
  const currentPage = get(state, `router.page.name`);

  if (currentPage !== 'PLAYER' && currentPage !== 'PREVIEW') {
    return;
  }

  const videoId = get(state, `router.page.params.videoId`);
  const videoDetail = selectors.videoDetail({ state, videoId });

  if (isEmpty(videoDetail) || videoDetail._deleted) {
    console.info('stopPlayWhenPlayOrVideoDeleted: done. video not found or deleted.');
    back();
  }

  if (currentPage === 'PREVIEW') {
    return;
  }

  const playStatusId = get(state, `router.page.params.playStatusId`);
  const playStatus = selectors.playStatusRemote({ state, videoId, playStatusId });

  if (isEmpty(playStatus) || playStatus._deleted) {
    console.info('stopPlayWhenPlayOrVideoDeleted: done. playStatus not found or deleted.');
    back();
  }
}

/**
 * When all plays of a Video are played, delete Video, VideoDownloadStatus & VideoPlayStatus from database.
 * On reactive work, video file would be deleted.
 */
function* deletePlayedVideos() {
  const state = yield select();
  const videos = selectors.allVideos(state);
  for (const i in videos) {
    const video = videos[i];
    const videoId = video._id;

    //Skip if video is attachment or already deleted.
    if (video.attachment || video._deleted) {
      continue;
    }

    const playPending = selectors.isAnyPlayPending({ state, videoId });
    console.debug('deletePlayedVideos: checking for updated', videoId, playPending);

    if (playPending) {
      continue;
    }

    console.info(`deletePlayedVideos: going to delete ${videoId}`);

    // Delete Video
    yield call(deleteDoc, [videoId]);

    // Deletes video download status
    const doc = selectors.videoDownloadStatus({ state, videoId });
    const newDoc = { ...doc, _deleted: true };
    if (!isEqual(doc, newDoc)) {
      yield call(saveDoc, newDoc);
    }

    // Deletes plays from db.
    const _plays = selectors.plays({ state, videoId });
    yield call(markSkippedIfPending, _plays);
    const playIds = map(_plays, '_id');
    yield call(deleteDoc, playIds);
  }
}

function markSkippedIfPending(plays) {
  const promises = plays
    .filter(play => play.status === 'PENDING')
    .map(doc => {
      console.info(`markSkippedIfPending: docId=${doc._id}`);
      doc = { ...doc, status: 'SKIPPED' };
      return saveDoc(doc);
    });
  return Promise.all(promises);
}

async function saveDoc(doc) {
  const response = db.save(doc);
  await response.localWrite;
  setTimeout(() => window.ChromeManager && window.ChromeManager.flushChromeCache(), 1000);
}

async function deleteDoc(docIds) {
  const response = db.delete(docIds);
  await response.localWrite;
  setTimeout(() => window.ChromeManager && window.ChromeManager.flushChromeCache(), 1000);
}

function* deletePlayedVideosAtInterval() {
  while (true) {
    yield delay(10000);
    yield call(deletePlayedVideos);
  }
}

/**
 * Init Saga.
 */
export default function* saga() {
  const state = yield select();
  const dbName = appSelectors.dbName(state);
  db = dreamdb.couchdb().db(dbName);

  yield all([
    //As START_PLAY is dispatched from `autoPlay` it's listening should be started earlier.
    takeEvery(actions.START_PLAY, onStartPlay),
    takeEvery(actions.PLAY_STARTED, onPlayStarted),
    call(autoPlay),
    call(playFlow),

    takeEvery(actions.OPEN_PREVIEW, onOpenPreview),
    takeEvery(actions.CLOSE_PREVIEW, onClosePreview),

    takeEvery(actions.START_PREVIEW, onStartPreview),
    takeEvery(actions.STOP_PREVIEW, onStopPreview),
    takeEvery(actions.STOP_PLAY, onStopPlay),

    takeEvery(actions.PLAY_PAUSED, onPlayPaused),
    takeEvery(actions.PLAY_COMPLETED, onPlayCompleted),
    takeEvery(app.actions.MEDIA_PLAY_PAUSE, onMediaPlayPause),

    call(deletePlayedVideos),
    fork(deletePlayedVideosAtInterval),

    debounce(2000, [CONNECTION_STATUS_CHANGED, DREAMDB_DOCS_UPDATED, DREAMDB_QUERY_SNAPSHOT], stopPlayWhenPlayOrVideoDeleted),
  ]);
}
