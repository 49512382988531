import { takeEvery, put, select, call, fork, delay, cancel, all, take } from 'redux-saga/effects';
import * as actions from './actions';
import { default as dreamdb, AuthStatus } from '@dw/dreamdb-client';
import { store } from '../store';
import * as app from '../app';
import * as selectors from './selectors';
import * as appSelectors from '../app/selectors.js';
import api from '../api.js';
import { uuidBase62 } from '@dreamworld/uuid-base62';
import lastIndexOf from 'lodash-es/lastIndexOf.js';

/**
 * It will resolved immediately, if device is connected with internet, otherwise resolved whenever device is
 * connected with internet.
 */
function waitForInternetConnection() {
  return new Promise((resolve, reject) => {
    if (navigator.onLine) {
      resolve();
      return;
    }

    const handler = e => {
      resolve();
      return;
    };

    window.addEventListener('online', handler, { once: true });
  });
}

/**
 * It returns deviceInfo using APIs provided by electron app.
 * @returns promise resolves deviceInfo {model, mac, srNo}
 *
 */
function* getDeviceInfo() {
  let deviceInfo;
  try {
    deviceInfo = yield call(() => window.Rpi.deviceInfo());
    deviceInfo.srNo = deviceInfo.serial;
    if (!deviceInfo.srNo) {
      console.warn('deviceInfo.srNo not found. using mockDeviceInfo()');
      return getMockDeviceInfo();
    }
  } catch (e) {
    console.warn('Rpi not found, so using mockDeviceInfo');
    return getMockDeviceInfo();
  }
  return deviceInfo;
}

function getMockDeviceInfo(deviceInfo) {
  deviceInfo = deviceInfo || {};
  deviceInfo.model = 'mock';
  if (!deviceInfo.srNo || deviceInfo.srNo.trim().length === 0) {
    const storedSrNo = localStorage.getItem('deviceInfo.srNo');
    if (storedSrNo) {
      deviceInfo.srNo = storedSrNo;
    } else {
      const srNo = localStorage.getItem('deviceInfo.srNo') || uuidBase62();
      deviceInfo.srNo = srNo;
      localStorage.setItem('deviceInfo.srNo', deviceInfo.srNo);
    }
  }

  if (!deviceInfo.mac || deviceInfo.mac.trim().length === 0) {
    const storedMac = localStorage.getItem('deviceInfo.mac');
    if (storedMac) {
      deviceInfo.mac = storedMac;
    } else {
      const mac = 'XX:XX:XX:XX:XX:XX'
        .replace(/X/g, function () {
          return '0123456789ABCDEF'.charAt(Math.floor(Math.random() * 16));
        })
        .toLowerCase();
      localStorage.setItem('deviceInfo.mac', mac);
      deviceInfo.mac = mac;
    }
  }

  return deviceInfo;
}


/**
 * It sets dreamdb deviceId as playerId.
 * It also updates deviceId if playerId isn't same in deviceId.
 * e.g. deviceId = `${playerId}-${base62Uuid}`
 * @param {String} playerId
 */
function* setDeviceIdAsPlayerId(playerId) {
  try {
    let deviceId = localStorage.getItem('dreamdb.deviceId');

    if (!deviceId) {
      deviceId = `${playerId}-${uuidBase62()}`;
      window.localStorage.setItem(`dreamdb.deviceId`, deviceId);
      return;
    }

    let existingPlayerId = deviceId.slice(0, lastIndexOf(deviceId, '-'));

    if (existingPlayerId !== playerId) {
      deviceId = `${playerId}-${uuidBase62()}`;
      window.localStorage.setItem(`dreamdb.deviceId`, deviceId);
    }
  } catch (e) {
    console.error('setDeviceIdAsPlayerId: failed. error=', e);
  }
}

/**
 * Connects Database.
 */
function* connectDB(skipNotification) {
  const state = yield select();
  const dreamDbUrl = app.selectors.config(state).dreamDbUrl;
  const playerId = selectors.playerId(state);
  const authToken = selectors.authToken(state);

  if (!dreamDbUrl || !playerId || !authToken) {
    console.error(`connectDB: Credentials are not found`, { dreamDbUrl, playerId, authToken });
    return;
  }

  try {
    dreamdb.connectToRedux(store);
    yield call(setDeviceIdAsPlayerId, playerId);
    const couchdb = dreamdb.connect(dreamDbUrl);
    const authStatusChanged = status => {
      if (status === AuthStatus.DONE) {
        couchdb.off('auth-status-changed', authStatusChanged);
        return;
      }
    };
    couchdb.on('auth-status-changed', authStatusChanged);
    couchdb.auth(`Basic ${btoa(playerId + ':' + authToken)}`);
    if (!skipNotification) {
      yield put(app.actions.dreamdbInitDone());
    }
    console.info('registration: connectDB done.');
  } catch (error) {
    console.error('registration: connectDB failed', error);
    yield put(actions.connectDBFailed());
  }
}

/**
 * It dispose dreamdb.
 */
function* clearDatabases() {
  try {
    yield dreamdb.couchdb().dispose();
  } catch(e) {
    console.info('clearDatabase: failed', e);
  }
}

let resetInProgress = false;
/**
 * It starts observe `RESET` redux action.
 * On that, it clears internal states - Databases and localStorage.
 */
function* reset() {
  if (resetInProgress) {
    return;
  }
  resetInProgress = true;

  try {
    console.info('reset: started');
    yield call(clearDatabases)
    yield call(() => window.VideoManager && window.VideoManager.deleteAll());
    yield put(actions.reset());
    window.location.reload();
  } catch (e) {
    console.error("reset: failed. error=", e);
  }
}

function* watchDreamdbForResetArchivedOrDestroyed(action) {
  const state = yield select();
  if (selectors.status(state) !== 'ACTIVE') {
    return;
  }

  if (action.type.indexOf('DREAMDB_') !== 0) {
    return;
  }
  let error;
  // console.log('watchDreamdbForResetArchivedOrDestroyed', action);
  switch(action.type) {
    case 'DREAMDB_QUERY_METADATA':
      error = action.metadata.error;
      if (error && (error.status === 401 || error.status === 404)) {
        console.info('query error encountered. Going to reset.', error);
        yield call(reset);
      }
      break;
    case 'DREAMDB_DATABASE_DELETED':
      console.info('database deleted. Going to reset.');
      yield call(reset);
      break;
    case 'DREAMDB_WRITE_DOCS_REMOTE_FAILED':
      error = action.error;
      if (error && (error.status === 401 || error.status === 404)) {
        console.info('writeDocs failed. Going to reset.', error);
        yield call(reset);
      }
      break;
  }
}


function* register() {
  console.info('register: started');
  yield call(waitForInternetConnection);
  const deviceInfo = yield call(getDeviceInfo);
  const state = yield select();
  const accessToken = appSelectors.config(state).registrationAccessToken;
  try {
    const res = yield call(api, `/players/register`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: deviceInfo,
      retryable: true,
      excludeErrors: [409],
    });
    yield put({ type: actions.DONE, payload: res });
    console.info('register: done');
  } catch(e) {
    if (e.status !== 409) {
      console.error('register: Invalid status: e=', e);
      throw e;
    }

    let status, playerCode;
    if (e.code === 'INVALID_STATUS') {
      console.info('register: failed with INVALID_STATUS. So, changing status to RESET_REQUIRED');
      status = 'RESET_REQUIRED';
      playerCode = e.message.split('=')[1].slice(0, -1);
      yield put({ type: actions.UPDATE_STATUS, status, playerCode });
      yield call(onResetRequired);
      throw e;
    }
    
    if (e.code === 'DESTROYED') {
      console.info('register: found destroyed.');
      status = 'DESTROYED';
      playerCode = e.message.split('=')[1].slice(0, -1);
      yield put({ type: actions.UPDATE_STATUS, status, playerCode });
      throw e;
    }

    console.error('register: Invalid status: e=', e);
    throw e;
  }

}

function* checkStatus() {
  const state = yield select();
  const playerId = selectors.playerId(state);
  try {
    const res = yield call(api, `/players/${playerId}`);
    return res.status;
  } catch(e) {
    if (e.status !== 404) {
      throw e;
    }
    return 'REJECTED';
  }
}

function* waitTillStatusIsActive() {
  console.info('registration: waitTillStatusIsActive: started');
  while (true) {
    const status = yield call(checkStatus);
    if (status === 'ACTIVE') {
      break;
    }

    if (status !== 'REGISTERED') {
      throw status;
    }
    
    console.info('registration: waitTillStatusIsActive: delay 10 seconds....');
    yield delay(10000); //10 seconds
  }
  console.info('registration: waitTillStatusIsActive: completed');
}

function* testQuery() {
  console.info('testQuery: started');
  const state = yield select();
  const dbName = appSelectors.dbName(state);
  while(true) {
    try {
      const db = dreamdb.couchdb().db(dbName);
      const queryRef = db.find({
        queryId: "test",
        selector: { type: 'Test'},
        useIndex: '_design/index-by-type',
      });
  
      //wait till localQuery result is not available
      yield call(() => {
        if (queryRef.metadata.inSync) {
          return;
        }
        let resolve, reject;
        const promise = new Promise((res, rej) => {resolve = res, reject = rej});
  
        queryRef.on('metadata', (metadata) => {
          if (metadata.inSync) {
            resolve();
            return;
          }
  
          if (metadata.error) {
            reject(metadata.error);
          }
        });
        return promise;
      });
      console.info('testQuery: done');
      break;
    } catch (error) {
      if (error.status === 401 || error.status === 404) {
        console.info('testQuery: failed. Would retry in 1 second', error);
        yield delay(1000);
      } else {
        console.error('testQuery: failed, with unknown error', error);
      }
    }
  }
  
}

function* activate() {
  console.info('activate: started');
  yield call(connectDB, true);
  console.info('activate: connectDB done');
  yield call(testQuery);
  yield put({ type: actions.UPDATE_STATUS, status: 'ACTIVE' });
  yield put(app.actions.dreamdbInitDone());
}

/**
 * Flow starts when registration status is ARCHIVED, and ends only when activation process is completed.
 */
function* onResetRequired() {
  while (true) {
    console.info('onResetRequired: waiting for user action - TRY AGAIN');
    yield take(actions.RETRY);
    try {
      yield call(register);
    } catch (e) {
      //ignore and cancel this flow
      return;
    }
    const status = yield call(checkStatus);
    if (status == 'ACTIVE') {
      console.info(`onResetRequired: found ACTIVE status, going to activate now`);
      yield call(activate);
      break;
    }
    console.info(`onResetRequired: status=${status}`);
  }
}

function* registrationFlow() {
  console.info('registrationFlow: started');
  const state = yield select();
  const registrationStatus = selectors.status(state);

  if (!registrationStatus) {
    try {
      yield call(register);
    } catch (e) {
      //ignore as handled in registration flow
      return;
    }
  }

  try {
    yield call(waitTillStatusIsActive);
  } catch (e) {
    if (e === 'REJECTED') {
      console.info('registrationFlow: found REJECTED. So, changing status to RESET_REQUIRED');
      yield put({ type: actions.UPDATE_STATUS, status: 'RESET_REQUIRED' });
      yield call(onResetRequired);
      return;
    }
  }

  yield call(activate);
  console.info('registrationFlow: completed');
}

/**
 * Init Saga.
 */
export default function* saga() {
  console.log('registration saga started');
  yield takeEvery('*', watchDreamdbForResetArchivedOrDestroyed);
  const state = yield select();
  const registrationStatus = selectors.status(state);
  console.log('registrationStatus', registrationStatus);
  if (!registrationStatus || registrationStatus === 'REGISTERED') {
    yield call(registrationFlow);
    return;
  }

  if (registrationStatus === 'ACTIVE') {
    yield call(connectDB);
    return;
  }

  if (registrationStatus === 'RESET_REQUIRED') {//Player status is ARCHIVED or ACTIVE (but credentials are invalid)
    yield call(onResetRequired);
    return;
  }

  if (registrationStatus === 'DESTROYED') {
    console.info('registration: No op as DESTROYED.');
    return;
  }
}
