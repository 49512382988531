// video is locked
export const AUTO_UPDATE_UNLOCK_STATE = 'VIDEOS_AUTO_UPDATE_UNLOCK_STATE';
export const UNLOCK_VIDEO = 'VIDEOS_UNLOCK_VIDEO';
export const CANCEL_UNLOCK = 'VIDEOS_CANCEL_UNLOCK';
export const UNLOCK_SUCCEED = 'VIDEOS_UNLOCK_VIDEO_SUCCEED';

// GPS
export const GPS_VALIDATION_STARTED = 'VIDEOS_GPS_VALIDATION_STARTED';
export const GPS_VALIDATION_SUCCEED = 'VIDEOS_GPS_VALIDATION_SUCCEED';
export const GPS_VALIDATION_FAILED = 'VIDEOS_GPS_VALIDATION_FAILED';

// RFID
export const RFID_VALIDATION_STARTED = 'VIDEOS_RFID_VALIDATION_STARTED';
export const RFID_VALIDATION_INVALID_TAG = 'VIDEOS_RFID_VALIDATION_INVALID_TAG';
export const RFID_VALIDATION_SUCCEED = 'VIDEOS_RFID_VALIDATION_SUCCEED';
export const RFID_VALIDATION_FAILED = 'VIDEOS_RFID_VALIDATION_FAILED';

// passcode
export const PASSCODE_VALIDATION_STARTED = 'VIDEOS_PASSCODE_VALIDATION_STARTED';

export const VERIFY_PASSCODE = 'VIDEOS_VERIFY_PASSCODE';
export const INVALID_PASSCODE = 'VIDEOS_INVALID_PASSCODE';
export const PASSCODE_VERIFIED = 'VIDEOS_PASSCODE_VERIFIED';
export const PASSCODE_FAILED = 'VIDEOS_PASSCODE_FAILED';

export const FETCH_PASSCODE = 'VIDEOS_AUTO_FETCH_PASSCODE';
export const FETCH_PASSCODE_SUCCEED = 'VIDEOS_FETCH_PASSCODE_SUCCEED';
export const FETCH_PASSCODE_FAILED = 'VIDEOS_FETCH_PASSCODE_FAILED';

/**
 *
 * @param {Object} unlockState e.g. {$playStatusId: { status: 'READY', gps: {status: 'NOT_APPLICABLE'}, rfId: {status: 'NOT_APPLICABLE'}, passcoce: {status: 'PENDING'}}}
 */
export const autoUpdateUnlockState = unlockState => {
  return {
    type: AUTO_UPDATE_UNLOCK_STATE,
    unlockState,
  };
};

export const unlock = playStatusId => {
  return {
    type: UNLOCK_VIDEO,
    playStatusId,
  };
};

export const cancelUnlock = playStatusId => {
  return {
    type: CANCEL_UNLOCK,
    playStatusId,
  };
};

export const unlockSucceed = playStatusId => {
  return {
    type: UNLOCK_SUCCEED,
    playStatusId,
  };
};

export const gpsValidationStarted = playStatusId => {
  return {
    type: GPS_VALIDATION_STARTED,
    playStatusId,
  };
};

export const gpsValidationSucceed = playStatusId => {
  return {
    type: GPS_VALIDATION_SUCCEED,
    playStatusId,
  };
};

export const gpsValidationFailed = (playStatusId, error) => {
  return {
    type: GPS_VALIDATION_FAILED,
    playStatusId,
    error,
  };
};

export const rfidValidationStarted = playStatusId => {
  return {
    type: RFID_VALIDATION_STARTED,
    playStatusId,
  };
};

export const rfidValidationInvalidTag = playStatusId => {
  return {
    type: RFID_VALIDATION_INVALID_TAG,
    playStatusId,
  };
};

export const rfidValidationSucceed = playStatusId => {
  return {
    type: RFID_VALIDATION_SUCCEED,
    playStatusId,
  };
};

export const rfidValidationFailed = (playStatusId, error) => {
  return {
    type: RFID_VALIDATION_FAILED,
    playStatusId,
    error,
  };
};

export const passcodeValidationStarted = playStatusId => {
  return {
    type: PASSCODE_VALIDATION_STARTED,
    playStatusId,
  };
};

export const verifyPasscode = (playStatusId, passcode) => {
  return {
    type: VERIFY_PASSCODE,
    playStatusId,
    passcode,
  };
};

export const passcodeVerified = playStatusId => {
  return {
    type: PASSCODE_VERIFIED,
    playStatusId,
  };
};

export const invalidPasscode = playStatusId => {
  return {
    type: INVALID_PASSCODE,
    playStatusId,
  };
}

export const passcodeFailed = (playStatusId, error) => {
  return {
    type: PASSCODE_FAILED,
    playStatusId,
    error,
  };
};

export const fetchPasscode = playStatusId => {
  return {
    type: FETCH_PASSCODE,
    playStatusId,
  };
};

export const fetchPasscodeSucceed = (playStatusId, passcode) => {
  return {
    type: FETCH_PASSCODE_SUCCEED,
    playStatusId,
    passcode,
  };
};

export const fetchPasscodeFailed = (playStatusId, error) => {
  return {
    type: FETCH_PASSCODE_FAILED,
    playStatusId,
    error,
  };
};
