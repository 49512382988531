import { call, fork } from "redux-saga/effects";
import { store } from '../store';
import * as actions from './actions';

function* observeMediaButton() {
  try {
    let firstKeyDown = 0;
    let shutdown = false;
    window.addEventListener('keydown', e => {
      if (e.key == 'MediaPlayPause') {
        if (!e.repeat) {
          firstKeyDown = new Date().getTime();
        } else {
          if (!shutdown && new Date().getTime() - firstKeyDown > 2000) {
            shutdown = true;
            console.log('observeMediaButton: do Shutdown');
            call(() => window.Rpi.powerOff());
          }
        }
      }
    });

    window.addEventListener('keyup', e => {
      if (e.key == 'MediaPlayPause' && !shutdown) {
        console.log('observeMediaButton: play/pause');
        window.dispatchEvent(new CustomEvent('media-play-pause'));
        store.dispatch({ type: actions.MEDIA_PLAY_PAUSE });
      }
    });
  } catch (error) {
    console.error(`observeMediaButton: Failed to listen on MediaPlayPause key`, error);
  }
}

export default function* () {
  yield fork(observeMediaButton);
}
