export const CHANGE_LANGUAGE = 'SETTINGS_CHANGE_LANGUAGE';
export const CHANGE_TIMEZONE = 'SETTINGS_CHANGE_TIMEZONE';

export const DISPLAYS_LOADED = 'SETTINGS_DISPLAYS_LOADED';
export const SET_RESOLUTION = 'SETTINGS_SET_RESOLUTION';

/**
 * Change language of the device.
 * @param {String} language Language
 */
export const changeLanguage = language => {
  return {
    type: CHANGE_LANGUAGE,
    language,
  };
};

/**
 * Changes timezone of the device.
 * @param {String} timezone Timezone. e.g. 'IST'
 */
export const changeTimezone = timezone => {
  return {
    type: CHANGE_TIMEZONE,
    timezone
  }
}

export const setResolution = (resolution) => {
  return {
    type: SET_RESOLUTION,
    resolution
  }
}
