import { takeEvery, all, put, select, call, delay } from 'redux-saga/effects';
import { ROUTE_CHANGED } from '@dreamworld/router';
import { dialog } from '../router/selectors';
import * as actions from './actions';

/**
 * Loads displays and current display resolution.
 */
function* loadDisplays() {
  try {
    const { displays, curResolution } = yield window.Display.getDisplays();
    yield put({ type: actions.DISPLAYS_LOADED, displays, curResolution });
  } catch (error) {
    console.error('loadDisplays: failed. error=', error);
  }
}

function* setResolution(action) {
  const { resolution } = action;
  try {
    yield window.Display.setResolution(resolution);
  } catch(error) {
    console.error('setResolution: failed. error=', error);
  }
}

function* routeChangeHandler() {
  const state = yield select();
  const currDialog = dialog(state).name;
  const currDialogParams = dialog(state).params;

  if (currDialog === 'SETTINGS' && currDialogParams?.view === 'screen-resolution') {
    yield call(loadDisplays);
  }
}

function* watchRouter() {
  yield call(routeChangeHandler);
  yield takeEvery(ROUTE_CHANGED, routeChangeHandler);
}

export default function* saga() {
  yield takeEvery(actions.SET_RESOLUTION, setResolution);
  yield call(watchRouter);
}
