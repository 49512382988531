import * as actions from './actions';
const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.CHANGE_LANGUAGE:
      return { ...state, language: action.language };
    case actions.CHANGE_TIMEZONE:
      return { ...state, timezone: action.timezone };
    case actions.DISPLAYS_LOADED:
      return { ...state, displays: action.displays, curResolution: action.curResolution };
    case actions.SET_RESOLUTION:
      return { ...state, curResolution: action.resolution };
    default:
      return state;
  }
};
