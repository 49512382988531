import { takeEvery, call, select, all, delay, debounce, take, fork, cancel } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import * as appSelectors from '../app/selectors.js';
import * as app from '../app';
import dreamdb from '@dw/dreamdb-client';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import map from 'lodash-es/map';
import { back, navigate } from '@dreamworld/router';
import isEqual from 'lodash-es/isEqual';

const CONNECTION_STATUS_CHANGED = 'DREAMDB_CONNECTION_STATUS_CHANGED';
const DREAMDB_DOCS_UPDATED = 'DREAMDB_DOCS_UPDATED';
const DREAMDB_QUERY_SNAPSHOT = 'DREAMDB_QUERY_SNAPSHOT';
let db; // Database instance of the DreamDB.


/**
 * Auto plays when any play is eligible to be played at present.
 */
function* autoPlay() {
  const state = yield select();
  const autoPlayUrl = selectors.autoPlayUrl(state);
  console.log(`autoPlay: autoPlayUrl=${autoPlayUrl}`);
  if (autoPlayUrl) {
    navigate(autoPlayUrl);
    return;
  }
}

function* playFlow() {
  while (true) {
    const { videoId, playStatusId } = yield take([actions.PLAY_STARTED]);
    const task = yield fork(function* () {
      while (true) {
        yield delay(10000);
        yield call(updatePlayedDurationOnRemote, videoId, playStatusId);
      }
    });
    yield take([actions.PLAY_COMPLETED, actions.PLAY_PAUSED]);
    yield cancel(task);
  }
}

/**
 * Updates played duration on dreamdb every 10 seconds.
 * @param {String} videoId Video Id
 * @param {String} playStatusId Play status Id
 */
function* updatePlayedDurationOnRemote(videoId, playStatusId) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  if (videoDetail._deleted || playStatus.playedDuration == videoDetail.duration) {
    return;
  }

  const doc = {
    ...playStatus,
    status: 'PLAYING',
  };
  console.info('updatePlayedDurationOnRemote', doc._id, doc.playedDuration);
  yield call(saveDoc, doc);
}


/**
 * Sets startAt time & status to `PLAYING`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id.
 */
function* onPlayStarted({ videoId, playStatusId }) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  if (videoDetail._deleted) {
    return;
  }
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  const doc = {
    ...playStatus,
    status: 'PLAYING',
    startedAt: Date.now(),
  };
  console.info('onPlayStarted', doc._id);
  yield call(saveDoc, doc);
}

/**
 * Sets status to `PAUSED`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id.
 */
function* onPlayPaused({ videoId, playStatusId }) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  console.log('onPlayPaused called', { videoDetail, videoId, playStatusId });
  if (videoDetail._deleted) {
    return;
  }
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  const doc = {
    ...playStatus,
    status: 'PAUSED',
  };
  console.info('onPlayPaused', doc._id);
  yield call(saveDoc, doc);
}

/**
 * Sets completedAt time & status to `COMPLETED`.
 * @param {*} param0
 *  @property {String} videoId Video Id.
 *  @property {String} playStatusId Play status Id.
 */
function* onPlayCompleted({ videoId, playStatusId }) {
  const state = yield select();
  const videoDetail = selectors.videoDetail({ state, videoId });
  if (videoDetail._deleted) {
    return;
  }
  const playStatus = selectors.playStatus({ state, videoId, playStatusId });
  const doc = {
    ...playStatus,
    status: 'PLAYED',
    completedAt: Date.now(),
  };
  console.info('onPlayCompleted', doc._id);
  yield call(saveDoc, doc);
  yield call(deletePlayedVideos);
  yield delay(2000);
  console.log('going to do poweroff');
  yield call(() => window.Rpi.powerOff());
  // yield window.RpiManager?.fsyncChromeCache && window.RpiManager.fsyncChromeCache();
}


/**
 * Invoked on `MediaPlayPause` key from the device.
 */
function* onMediaPlayPause() {
  const state = yield select();
  const currentPage = get(state, `router.page.name`);

  if (currentPage === 'PLAYER' || currentPage === 'PREVIEW') {
    return;
  }

  const currentAvailablePlayUrl = selectors.currentAvailablePlayUrl(state);
  if (currentAvailablePlayUrl) {
    navigate(currentAvailablePlayUrl);
    return;
  }

  const firstPreviewUrl = selectors.firstPreviewUrl(state);
  if (firstPreviewUrl) {
    navigate(firstPreviewUrl);
    return;
  }
}

/**
 * When current page is PLAYER & that play or video is deleted, stop play & navigates user to home page.
 */
function* stopPlayWhenPlayOrVideoDeleted() {
  const state = yield select();
  const currentPage = get(state, `router.page.name`);

  if (currentPage !== 'PLAYER' && currentPage !== 'PREVIEW') {
    return;
  }

  
  const videoId = get(state, `router.page.params.videoId`);
  const videoDetail = selectors.videoDetail({ state, videoId });

  if (isEmpty(videoDetail) || videoDetail._deleted) {
    console.info("stopPlayWhenPlayOrVideoDeleted: done. video not found or deleted.");
    back();
  }
  
  if (currentPage === 'PREVIEW') {
    return;
  }

  const playStatusId = get(state, `router.page.params.playStatusId`);
  const playStatus = selectors.playStatusRemote({ state, videoId, playStatusId });

  if (isEmpty(playStatus) || playStatus._deleted) {
    console.info("stopPlayWhenPlayOrVideoDeleted: done. playStatus not found or deleted.");
    back();
  }
}


/**
 * When all plays of a Video are played, delete Video, VideoDownloadStatus & VideoPlayStatus from database.
 * On reactive work, video file would be deleted.
 */
function* deletePlayedVideos() {
  const state = yield select();
  const videos = selectors.allVideos(state);
  for (const i in videos) {
    const video = videos[i];
    const videoId = video._id;
    const playPending = selectors.isAnyPlayPending({ state, videoId });
    console.debug('deletePlayedVideos: checking for updated', videoId, playPending);

    if (playPending) {
      continue;
    }

    console.info(`deletePlayedVideos: going to delete ${videoId}`);

    // Delete Video
    yield call(deleteDoc, [videoId]);

    // Deletes video download status
    const doc = selectors.videoDownloadStatus({ state, videoId });
    const newDoc = { ...doc, _deleted: true };
    if (!isEqual(doc, newDoc)) {
      yield call(saveDoc, newDoc);
    }

    // Deletes plays from db.
    const _plays = selectors.plays({ state, videoId });
    yield call(markSkippedIfPending, _plays);
    const playIds = map(_plays, '_id');
    yield call(deleteDoc, playIds);
  }
}

function markSkippedIfPending(plays) {
  const promises = plays
    .filter((play) => play.status === 'PENDING')
    .map((doc) => {
      console.info(`markSkippedIfPending: docId=${doc._id}`);
      doc = {...doc, status: 'SKIPPED'};
      return saveDoc(doc);
    });
  return Promise.all(promises);
}

async function saveDoc(doc) {
  const response = db.save(doc);
  await response.localWrite;
  setTimeout(() => window.ChromeManager && window.ChromeManager.flushChromeCache(), 1000);
}

async function deleteDoc(docIds) {
  const response = db.delete(docIds);
  await response.localWrite;
  setTimeout(() => window.ChromeManager && window.ChromeManager.flushChromeCache(), 1000);
}

function* deletePlayedVideosAtInterval() {
  while (true) {
    yield delay(10000);
    yield call(deletePlayedVideos);
  }
}

/**
 * Init Saga.
 */
export default function* saga() {
  const state = yield select();
  const dbName = appSelectors.dbName(state);
  db = dreamdb.couchdb().db(dbName);

  yield all([
    call(autoPlay),
    call(playFlow),
    takeEvery(actions.PLAY_STARTED, onPlayStarted),
    takeEvery(actions.PLAY_PAUSED, onPlayPaused),
    takeEvery(actions.PLAY_COMPLETED, onPlayCompleted),
    takeEvery(app.actions.MEDIA_PLAY_PAUSE, onMediaPlayPause),

    call(deletePlayedVideos),
    fork(deletePlayedVideosAtInterval),

    debounce(2000, [CONNECTION_STATUS_CHANGED, DREAMDB_DOCS_UPDATED, DREAMDB_QUERY_SNAPSHOT], stopPlayWhenPlayOrVideoDeleted),
  ]);
}
