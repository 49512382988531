import { get } from 'lodash-es';
import * as actions from './actions-unlock.js';
import { ReduxUtils } from '@dreamworld/pwa-helpers/redux-utils.js';

const INTIAL_VALIDATION_STATE = {};

export default (state = INTIAL_VALIDATION_STATE, action) => {
  const { videoId, playStatusId } = action;
  let passcode;
  switch (action.type) {
    case actions.AUTO_UPDATE_UNLOCK_STATE:
      return { ...state, ...action.unlockState };

    // case actions.UNLOCK_VIDEO:
    //   return ReduxUtils.replace(state, `${playStatusId}.status`, 'IN_PROGRESS');


    case actions.GPS_VALIDATION_STARTED:
      state = ReduxUtils.replace(state, `${playStatusId}.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${playStatusId}.gps.status`, 'IN_PROGRESS');

    case actions.GPS_VALIDATION_SUCCEED:
      return ReduxUtils.replace(state, `${playStatusId}.gps.status`, 'DONE');

    case actions.GPS_VALIDATION_FAILED:
      state = ReduxUtils.replace(state, `${playStatusId}.gps.status`, 'FAILED');
      state = ReduxUtils.replace(state, `${playStatusId}.gps.error`, action.error);
      return state;

    case actions.RFID_VALIDATION_STARTED:
      state = ReduxUtils.replace(state, `${playStatusId}.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${playStatusId}.rfid.status`, 'IN_PROGRESS');

    case actions.RFID_VALIDATION_INVALID_TAG:
      return ReduxUtils.replace(state, `${playStatusId}.rfid.invalid`, true);

    case actions.RFID_VALIDATION_SUCCEED:
      state = ReduxUtils.replace(state, `${playStatusId}.rfid.invalid`, false);
      return ReduxUtils.replace(state, `${playStatusId}.rfid.status`, 'DONE');

    case actions.RFID_VALIDATION_FAILED:
      state = ReduxUtils.replace(state, `${playStatusId}.rfid.status`, 'FAILED');
      state = ReduxUtils.replace(state, `${playStatusId}.rfid.error`, action.error);
      return state;

    case actions.PASSCODE_VALIDATION_STARTED:
      state = ReduxUtils.replace(state, `${playStatusId}.status`, 'IN_PROGRESS');
      return ReduxUtils.replace(state, `${playStatusId}.passcode.status`, 'IN_PROGRESS');

    case actions.VERIFY_PASSCODE:
      state = ReduxUtils.replace(state, `${playStatusId}.passcode.validating`, true);
      return ReduxUtils.replace(state, `${playStatusId}.passcode.invalid`, false);

    case actions.PASSCODE_VERIFIED:
      passcode = get(state, `${playStatusId}.passcode`);
      return ReduxUtils.replace(state, `${playStatusId}.passcode`, {
        status: 'DONE',
        auto: passcode.auto,
      });

    case actions.INVALID_PASSCODE:
      state = ReduxUtils.replace(state, `${playStatusId}.passcode.validating`, false);
      return ReduxUtils.replace(state, `${playStatusId}.passcode.invalid`, true);

    case actions.PASSCODE_FAILED:
      passcode = get(state, `${playStatusId}.passcode`);
      return ReduxUtils.replace(state, `${playStatusId}.passcode`, {
        status: 'FAILED',
        auto: passcode.auto,
      });

    case actions.FETCH_PASSCODE:
      return ReduxUtils.replace(state, `${playStatusId}.passcode.auto`, true);

    case actions.FETCH_PASSCODE_SUCCEED:
      return state;

    case actions.FETCH_PASSCODE_FAILED:
      return state;

    case actions.UNLOCK_SUCCEED:
      return ReduxUtils.replace(state, `${playStatusId}.status`, 'DONE');

    default:
      return state;
  }
};
