import * as actions from './actions';

const defaultConfig = {
  apiBaseUrl: 'https://api.smpv.dreamworld.dev',
  dreamDbUrl: 'wss://ws.smpv.dreamworld.dev',
  playerServerUrl: 'http://localhost',
  playerServerToken: 'xxxx', //it's being overriden through electronConfig (device-config.json)
  dbNamePrefix: '',
  videosBasePath: '/videos',
  videosDir: `/videos`,
  playBufferTime: 20 * 60 * 1000, // 20 minutes. It's buffer time given after play end time. It's useful when video is paused due to some technical issue.
  resumePlaybackTime: 10, // 10 seconds.
  reduxDevToolsPath: '',
  version: window.APP_VERSION,
  registrationAccessToken: 'xxxx' //it's being overriden through electronConfig (device-config.json)
};

const electronConfig = window.smpvConfig || {};
const INITIAL_STATE = {
  bootTime: Date.now(),
  config: {...defaultConfig, ...electronConfig },
  upgradeStatus: 'NOT_AVAILABLE',
  dreamdbInitDone: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPGRADE_AVAILABLE:
      return {...state, upgradeStatus: 'AVAILABLE'};
    case actions.UPGRADE:
      return { ...state, upgradeStatus: 'CONFIRMED'};
    case actions.DREAMDB_INIT_DONE:
      return {...state, dreamdbInitDone: true};
    default:
      return state;
  }
};
