import { LitElement, html, css } from 'lit';
import { connect } from '@dreamworld/pwa-helpers/connect-mixin';
import { init as initRouter, navigate, registerFallbackCallback } from '@dreamworld/router';
import URLS from '../redux/router/urls.js';
import { store } from '../redux/store.js';
import sharedStyles from '../styles/shared-styles.js';
import installSW from './install-sw.js';
import * as routerSelectors from '../redux/router/selectors.js';

if (!window.location.pathname.startsWith('/video-controller')) {
  import('../redux/network-status-indicator/index.js');
}

export class AppShell extends connect(store)(LitElement) {
  static get properties() {
    return {
      /**
       * Computed from router data.
       */
      _pageName: { type: String, reflect: true, attribute: 'page-name' },

      /**
       * Dialog data from router
       */
      _dialog: { type: Object },
    };
  }

  constructor() {
    super();
    initRouter(URLS, store);
    registerFallbackCallback(this._routerFallbackCallback);
  }

  firstUpdated(changedProps) {
    super.firstUpdated(changedProps);
    installSW();
    import('./icons/smpv-icons.js');
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: flex;
          min-height: 100vh;
          background: var(--mdc-theme-background);
          color: var(--mdc-theme-text-primary-on-background);
          padding-left: 32px;
          padding-right: 32px;
        }


        :host([page-name='VIDEO_CONTROLLER']) {
          background: transparent;
        }


      `,
    ];
  }

  get _registrationPageTemplate() {
    if (this._pageName !== 'REGISTRATION') {
      return '';
    }

    import('./registration-page.js');
    return html`<registration-page></registration-page>`;
  }

  get _homePageTemplate() {
    if (this._pageName !== 'HOME') {
      return '';
    }
    import('./home-page.js');
    return html`<home-page></home-page>`;
  }

  get _playStatusPageTemplate() {
    if (this._pageName !== 'PLAY_STATUS') {
      return '';
    }
    import('./play-status-page.js');
    return html`<play-status-page></play-status-page>`;
  }

  get _previewPage() {
    if (this._pageName !== 'PREVIEW') {
      return '';
    }
    import('./preview-page.js');
    return html`<preview-page></preview-page>`;
  }

  get _playerPage() {
    if (this._pageName !== 'PLAYER') {
      return '';
    }
    import('./player-page.js');
    return html`<player-page></player-page>`;
  }

  get _videoControllerPage() {
    if (this._pageName !== 'VIDEO_CONTROLLER') {
      return '';
    }
    import('./video-controller-page.js')
    return html`<video-controller-page></video-controller-page>`;
  }

  render() {
    return html`${this._registrationPageTemplate} ${this._homePageTemplate} ${this._playStatusPageTemplate} ${this._previewPage}
    ${this._playerPage}${this._unlockDialogTemplate}${this._drawerTemplate}${this._videoControllerPage}`;
  }

  get _drawerTemplate() {
    const { name } = this._dialog;
    if (name !== 'SETTINGS' && name !== 'WIFI_SETTINGS' && name !== 'ETHERNET') {
      return;
    }

    return html` <smpv-drawer opened .view="${name}"></smpv-drawer> `;
  }

  get _unlockDialogTemplate() {
    const { name, params } = this._dialog;
    if (name !== 'UNLOCK_VIDEO' || !params?.playStatusId) return;

    import('./video-unlock-dialog.js');
    return html`<video-unlock-dialog .opened=${true} .playStatusId=${params.playStatusId}></video-unlock-dialog>`;
  }

  /**
   * Navigates to home page when no router history found on router back.
   */
  _routerFallbackCallback() {
    navigate('/', true);
  }

  stateChanged(state) {
    this._pageName = routerSelectors.currentPageName(state);
    this._dialog = routerSelectors.dialog(state);
  }
}

customElements.define('app-shell', AppShell);
